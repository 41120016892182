<template lang="">
  <modal-padrao
    ref="modal-setor"
    :max-width="1000"
    :titulo="$t('componentes.input_setor.titulo_modal')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="aplicarSelecionados"
  >
    <div>
      <div class="d-flex align-end">
        <input-text
          v-model="filtro.filter"
          :label="$t('componentes.input_setor.nome')"
          class="flex-fill"
        />
        <botao-padrao outlined color="secondary" class="ml-2" @click="filtrar">
          {{ $t('geral.botoes.filtrar') }}
        </botao-padrao>
      </div>
      <tabela-padrao
        v-model="selecionados"
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        sem-acoes
        :selecionar-apenas-um="!multiplos"
        @paginar="listar"
      />
    </div>
  </modal-padrao>
</template>
<script>
import SetorService from '@common/services/cadastros/SetorService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('componentes.input_setor.tabela.nome'),
            sortable: false,
          },
          {
            value: 'tipoSetor.descricao',
            text: this.$t('componentes.input_setor.tabela.descricao'),
            sortable: false,
          },
          {
            value: 'sigla',
            text: this.$t('componentes.input_setor.tabela.sigla'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  mounted() {
    this.listar();
  },
  methods: {
    abrirModal: function (dadosSetor) {
      this.$refs['modal-setor'].abrirModal();
      if (dadosSetor) {
        this.selecionados = _.cloneDeep(dadosSetor);
        if (!this.multiplos) this.selecionados = [this.selecionados];
      }
    },
    aplicarSelecionados: function () {
      this.$refs['modal-setor'].fecharModal();
      let selecionados = this.selecionados;
      if (!this.multiplos) {
        selecionados = null;
        if (this.selecionados.length > 0) {
          selecionados = this.selecionados[0];
        }
      }

      this.$emit('confirmar-setor', selecionados);
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        participanteId: localStorage.getItem('participanteLogadoId'),
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      SetorService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
