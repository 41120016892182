<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{
          $t(
            'modulos.ocorrencia_padrao.formulario.abas.dados_ocorrência_padrao'
          )
        }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.ocorrencia_padrao.formulario.abas.setores') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-ocorrencia-padrao ref="dados-ocorrencia-padrao" :form="form" />
      </v-tab-item>
      <v-tab-item>
        <setores :form="form" />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import OcorrenciaPadraoService from '@common/services/cadastros/OcorrenciaPadraoService';
import DadosOcorrenciaPadrao from './components/DadosOcorrenciaPadrao';
import Setores from './components/Setores';
import { OcorrenciaPadraoModel } from '@common/models/cadastros/OcorrenciaPadraoModel';
import helpers from '@common/utils/helpers';
export default {
  components: {
    DadosOcorrenciaPadrao,
    Setores,
  },
  props: ['id'],
  data() {
    return {
      valido: false,
      indiceAba: 0,
      form: new OcorrenciaPadraoModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.ocorrencia_padrao.titulos.editar');
      return this.$t('modulos.ocorrencia_padrao.titulos.novo');
    },
  },
  mounted: async function () {
    if (this.id) {
      await this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'OcorrenciaPadrao', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'OcorrenciaPadrao', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await OcorrenciaPadraoService.buscar(this.id)
        .then((res) => {
          this.form = new OcorrenciaPadraoModel(res.data);
          this.form.setores?.map((item) => {
            item.tipoSetorDescricao = item.tipoSetor;
            item.id = item.setorId;
          });
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.ocorrencia_padrao.erros.id_invalido')
          );
        })
        .finally(() => {
          this.valido = true;
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (!this.validarOcorrenciaPadrao()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    validarOcorrenciaPadrao: function () {
      if (this.$refs['dados-ocorrencia-padrao']) {
        this.valido =
          this.$refs['dados-ocorrencia-padrao'].$refs.form.validate();
      }

      if (!this.valido) {
        this.indiceAba = 0;
      }
      // if (this.form.setores.length == 0) {
      //   this.valido = false;
      //   this.indiceAba = 1;
      //   this.toastAlerta(
      //     this.$t(`modulos.ocorrencia_padrao.erros.setores_preencher`)
      //   );
      //   return;
      // }
      return this.valido;
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OcorrenciaPadraoService.salvar(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.ocorrencia_padrao.cadastro_sucesso`)
          );
          this.$router.push({ name: 'ocorrencia-padrao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'ocorrencia-padrao' });
      });
    },
  },
};
</script>
