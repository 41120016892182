<template>
  <div>
    <v-form
      ref="form"
    >
      <div class="row mt-2">
        <input-text
          v-model="form.nome"
          class="col-12"
          :label="$t('modulos.ocorrencia_padrao.formulario.nome')"
          obrigatorio
          :max="150"
        />
        <input-textarea
          v-model="form.ocorrencia"
          class="col-12"
          :label="$t('modulos.ocorrencia_padrao.formulario.ocorrencia')"
          obrigatorio
          :max="2000"
        />
        <input-textarea
          v-model="form.acaoCorretiva"
          class="col-12"
          :label="$t('modulos.ocorrencia_padrao.formulario.acao_corretiva')"
          obrigatorio
          :max="2000"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
export default {
  props: {
    form: {},
  },
};
</script>
