<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          outlined
          class="mt-3"
          color="danger"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        class="mt-3"
        @click="abrirNovo"
      >
        {{ $t('modulos.ocorrencia_padrao.formulario.setores.botoes.novo') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="form.setores"
      class="mt-2"
      :colunas="tabela.colunas"
    />

    <setor-modal
      ref="modal-setor"
      @confirmar-setor="confirmarNovoSetor"
    />
  </div>
</template>
<script>
import SetorModal from './modais/SetorModal';
export default {
  components: {
    SetorModal,
  },
  props: {
    form: {},
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nome',
            text: this.$t('modulos.ocorrencia_padrao.formulario.setores.nome'),
            sortable: true,
          },
          {
            value: 'tipoSetorDescricao',
            text: this.$t('modulos.ocorrencia_padrao.formulario.setores.tipo'),
            sortable: true,
          },
          // {
          //   value: 'posicao',
          //   text: this.$t('modulos.ocorrencia_padrao.formulario.setores.posicao'),
          //   sortable: true,
          // },
          {
            value: 'sigla',
            text: this.$t('modulos.ocorrencia_padrao.formulario.setores.sigla'),
            sortable: true,
          }
        ],
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  methods: {
    confirmarNovoSetor: function (form) {
      if(form.length) {
        form.map(item => {
          item.tipoSetorDescricao = item.tipoSetorDescricao ? item.tipoSetorDescricao : item.tipoSetor.descricao
          item.setorId = item.id
          item.id = null
        })
      this.form.setores = form;
      }
    },
    abrirNovo: function () {
        this.$refs['modal-setor'].abrirModal(this.form.setores);
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.setores.splice(this.form.setores.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
